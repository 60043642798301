<template>
<div>
	<v-list-item>
		<v-list-item-icon small>	
			<PollResponseAvatar :id="id" v-if="poll.status == 'submitted'"></PollResponseAvatar>	
		</v-list-item-icon>
		<v-list-item-content three-line>
			<v-list-item-title>
				<v-text-field class="mt-0" outlined :placeholder="mwtr.PollNickname" v-model="nickname" dense hide-details></v-text-field>	
			</v-list-item-title>
			<v-list-item-subtitle>
				{{link}}
			</v-list-item-subtitle>
			<v-list-item-subtitle class="text-right">
				<CopyButton :text="link" small></CopyButton>
				<v-tooltip right>
					<template v-slot:activator="{on}">					
						<v-btn icon @click="deletePoll" small v-on="on"> 
							<v-icon small>mdi-trash-can</v-icon>
						</v-btn>
					</template>
					{{mwtr.DeleteButton}}
				</v-tooltip>
				<ViewPoll v-if="poll.response" :poll="poll" :id="id"></ViewPoll>
			</v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-action>
		</v-list-item-action>
	</v-list-item>
</div>
</template>

<style lang="less" scoped>
	.v-list-item__icon {
		margin-right: 10px !important;
	}
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import ViewPoll from "@/components/survey/poll/ViewPoll";
	import PollResponseAvatar from "@/components/survey/poll/PollResponseAvatar"
	import CopyButton from "@/components/elements/CopyButton";
	export default {
		name: "EditPoll", 
		props: {
			id: {},
			poll: {}
		},
		data: () => {
			return {
				nickname: ""
			}
		},
		components: {
			CopyButton,
			ViewPoll,
			PollResponseAvatar
		},
		computed: {
			link(){
				var url = window.location.origin
				return url + "/poll/" + this.id
			}
			// ...Vuex.mapState({})
		},
		methods: {
			deletePoll(){
				this.$store.dispatch("deleteDoc", {id: this.id, collection: "collaborations" })
			}, 
		},
		watch: {
			nickname(value){
				if( !value ){
					return;
				}
				if( value == this.poll.nickname ){
					return;
				}
				this.$store.dispatch("updateDoc", {
					doc: `collaborations/${this.id}`, 
					data: {
						nickname: value
					} 
				})
			}
		},
		created(){
			this.nickname = this.poll.nickname	
		}

	}
// </script>"