<template>
<div>
	<p v-if="!havePolls">{{mwtr.NoPollsFound}}</p>
	<v-list dense>
		<EditPoll v-for="(poll, key) in currentPolls" :key="key" :id="key" :poll="poll"></EditPoll>
	</v-list>
</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import EditPoll from "@/components/survey/poll/EditPoll"
	export default {
		name: "ExistingPoll", 
		props: {
			
		},
		data: () => {
			return {
			}
		},
		components: {
			EditPoll
		},
		computed: {
			currentPolls(){
				return this.$store.getters.currentPolls
			},
			havePolls(){
				return Object.keys(this.currentPolls).length > 0 || false
			}
			// ...Vuex.mapState({})
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"