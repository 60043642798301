<template>
<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
	<template v-slot:activator="{on}">		
		<v-btn small icon v-on="on" v-if="poll.response">
			<v-icon small>mdi-magnify</v-icon>
		</v-btn>
	</template>
	<v-card>
		<v-card-title>
			{{poll.nickname || mwtr.Poll}}
		</v-card-title>
		<v-card-text>
			<SingleResponse :updated="poll.updated" :data="poll.response"></SingleResponse>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn @click="dialog = false">{{mwtr.CloseButton}}</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import SingleResponse from "@/components/survey/results/SingleResponse"
	export default {
		name: "ViewPoll", 
		props: {
			poll: {},
			id: {}
		},
		data: () => {
			return {
				dialog: false
			}
		},
		components: {
			SingleResponse
		},
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"