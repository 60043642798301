<template>
<v-sheet>
	<Section v-if="currentView"></Section>
	<div v-else>
		<AssessmentOverview v-if="canAccessAssessment"></AssessmentOverview>
	</div>
</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Section from "@/components/survey/render/Section"
	import AssessmentOverview from "@/components/survey/AssessmentOverview"
	export default {
		name: "Assessment", 
		props: {
			
		},
		data: () => {
			return {
			}
		},
		components: {
			Section,
			AssessmentOverview
		},
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment,
			}),
			currentView(){
				return this.$store.getters.currentView
			}, 
			canAccessAssessment(){
				return this.$store.getters.canAccessAssessment
			}
		}
	}
// </script>"