import { render, staticRenderFns } from "./Section.vue?vue&type=template&id=07357f0d&"
import script from "./Section.vue?vue&type=script&lang=js&"
export * from "./Section.vue?vue&type=script&lang=js&"
import style0 from "./Section.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VBtn,VSheet,VSpacer,VToolbar})
