<template>
	<v-tooltip top>
		<template v-slot:activator="{on}">		
			<v-avatar size="20" v-on="on" :color="color" class="mr-1">
				{{initial}}
			</v-avatar>
		</template>	
		<span v-if="poll.nickname.length">{{poll.nickname}}'s </span>Antwort auf Ihre Mini-Umfrage
	</v-tooltip>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "PollResponseAvatar", 
		props: {	
			id: {}, 
		},
		computed: {
			polls() {
				return this.$store.getters.currentPolls
			},
			poll(){
				return this.polls[this.id];
			},
			initial(){
				return this.poll.nickname.charAt(0) || " "
			},
			color(){
				var polls = this.polls;
				var index = Object.keys(polls).indexOf( this.id )
				return this.mwstylesettings.Colors[index]
			}
		}
		// created(){
			
		// }

	}
// </script>"