<template>
<div>
	<p class="overline">
		{{ updated.toDate() | moment("hh:mm, MMMM Do YYYY") }}
	</p>
	<SingleQuestionResponse 
	v-for="question in questions" 
	:key="question.id" 
	:data="data[question.id]" 
	:question="question"></SingleQuestionResponse>
</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import SingleQuestionResponse from "@/components/survey/results/SingleQuestionResponse";
	// import - from "@/components/"
	export default {
		name: "SingleResponse", 
		props: {
			data: {}, 
			updated: {}
		},
		data: () => {
			return {

			}
		},
		components: {
			SingleQuestionResponse
		},
		computed: {
			questions(){
				return this.$store.getters.questionArray;
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"