<template>
	<span>
		<v-tooltip right>
			<template v-slot:activator="{on}">					
				<v-btn :small="small" class="ma-2" v-on="on" @click="copy" icon>
					<v-icon :small="small" >mdi-content-copy</v-icon>
				</v-btn>
			</template>
			{{mwtr.CopyButton}}
		</v-tooltip>
		<Notice :trigger="copied" :message="mwtr.Copied" @close="copied = false"></Notice>
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Notice from "@/components/notices/Notice"
	export default {
		name: "CopyButton", 
		props: {
			text: {},
			small: {}
		},
		data: () => {
			return {
				copied: false
			}
		},
		components: {
			Notice
		},
		methods: {
			copy(){ 
				this.copied = true;
				navigator.clipboard.writeText(this.text);
			}
		}

	}
// </script>"