<template>
<div>
	<p class="subtitle-1">
		<b>{{question.title}}</b>
	</p>
	<p v-if="answerResponse">
		{{answerResponse}}
	</p>
	<p v-else class="no-answer">
		{{mwtr.NoAnswer}}
	</p>
</div>
</template>

<style lang="less">
	.no-answer{
		font-style: italic;
	}
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "SingleQuestionResponse", 
		props: {
			data: {}, 
			question: {}
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			answerResponse(){
				var value = this.data;
				if( !value ){
					return false;
				}
				if(!this.question.answerOptions){
					return;
				}
				var response = Object.values(this.question.answerOptions).find( d => d.value == value)
				return response.text;
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"