<template>
	<div class="pl-4 mb-5">
		<v-row class="pa-1 pl-4" style="position: relative">
			<v-switch color="primary" :label="mwtr.DelegateSection" v-model="delegated" @change="delegateSection"></v-switch>
		</v-row>
		<v-row class="pa-1 pl-4" v-if="delegated">
			<v-text-field v-model="link" disabled ref="linkField"> </v-text-field>	
			<CopyButton :text="link"></CopyButton>
		</v-row>
	</div>
</template>

<style lang="less">
	.v-btn.right {
		position: absolute;
		right: 10px;
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import CopyButton from "@/components/elements/CopyButton"
	export default {
		name: "Delegate", 
		props: {
			id: {}
		},
		data: () => {
			return {
				dialog: false,
				delegated: false,
				collaboration: false,
			}
		},
		components: {
			CopyButton
		},
		computed: {
			...Vuex.mapState({
				section(state){
					return state.assessment.sections[this.id]
				}
			}),
			link(){
				var url = window.location.origin
				return url + "/ref/" + this.collaboration
			}
		},
		watch: {
			section: {
				immediate: true, 
				handler(){
					if( this.section.status == "delegated" ){
						this.delegated = true;
					}
					else{
						this.delegated = false;
					}
					if( this.section.collaboration ){
						this.collaboration = this.section.collaboration;
					}
					else{
						this.collaboration = false;
					}
				}
			}
		},
		methods: {
			delegateSection(){
				const self = this;
				if( this.delegated ){
					self.$store.dispatch("collaborations/delegate", self.id ).then( (id) => {
						self.collaboration = id;
					})
				}
				else{
					self.$store.dispatch("collaborations/undelegate", {id: self.id, collabId: self.collaboration })
				}
			}, 
		}
		// created(){
			
		// }

	}
// </script>"