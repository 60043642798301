<template>
<v-alert class="mt-2" color="secondary">
	<span v-if="isDelegate">
		Das Assessment ist hiermit beendet. Der Geschäftsbereich IT bedankt sich für Ihre Teilnahme!
	</span>
	<span v-else>
		Keine Abschnitte gefunden!
	</span>
</v-alert>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "NoSections", 
		props: {
			
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment
			}),
			isDelegate(){
				return this.assessment && this.assessment.role == "delegate"
			}
		},
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"