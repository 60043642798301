<template>
	<v-dialog v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
		<template v-slot:activator="{on}">
			<v-btn v-on="on" color="primary">{{mwtr.LaunchPolls}}</v-btn>
		</template>
		<v-card>
			<v-card-title>{{mwtr.PollOverview}}</v-card-title>
			<v-card-text>
				<p>	Hier können Sie die aktuelle Thematik per „Mini-Umfrage“ an eine/n zuständige/n Mitarbeiter/in delegieren. </p>
				<ol class="mb-2">
					<li>Bitte legen Sie pro zu befragenden Mitarbeiter/in eine Mini-Umfrage an. Hierzu klicken Sie auf „Neue Mini-Umfrage“. </li>
					<li>Anschließend erscheint ein Eingabefeld, dass Sie individuell betiteln können. Durch Eintragen eines „Titels“ im nachfolgenden Eingabefeld wird die Mini-Umfrage für Sie später eindeutig zuordbar.</li>
					<li>Nun kopieren Sie den angezeigten Link unterhalb des Eingabefeldes und leiten den Link an ihn/sie weiter. </li>
				</ol>
				<p>
					Der/Die Mitarbeiter:in gibt abschließend seine persönliche Einschätzung zur relevanten Thematik ab und Sie werden über den Abschluss benachrichtigt. 
				</p>
				<ExistingPolls></ExistingPolls>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="newPoll" color="primary">{{mwtr.NewPoll}}</v-btn>
				<v-btn @click="dialog = false">{{mwtr.CloseButton}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import ExistingPolls from "@/components/survey/poll/ExistingPolls"
	export default {
		name: "CreatePoll", 
		props: {
			
		},
		data: () => {
			return {
				dialog: false
			}
		},
		components: {
			ExistingPolls
		},
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment,
				currentGoal: state => state.currentGoal,
				currentDepartment: state => state.currentDepartment
			}), 
			currentGoalGroup(){
				var part = this.$store.getters.currentPart;
				return part.goalGroup;
			}
		},
		methods: {
			newPoll(){
				var poll = {
					goal: this.currentGoal, 
					assessment: this.assessment.id, 
					department: this.currentDepartment, 
					goalGroup: this.currentGoalGroup
				}
				this.$store.dispatch("collaborations/newPoll", poll)
			}
		}

	}
// </script>"