<template>
	<v-sheet>
		<template  v-if="accessGranted">
			<v-toolbar prominent flat class="mb-12 goal-toolbar">
				<span class="goal-title ig-header">{{goal.name}}</span>
				<v-spacer></v-spacer>
				<CreatePoll :section="sectionId" :goal="view.goal"></CreatePoll>
			</v-toolbar>
			<Questions 
			:questions="questions"
			:error="error !== false"
			:errorMessage="errorMessage ? errorMessage : ''"
			:data="goal.response.data"
			@update="updateValue"
			></Questions>
			<p class="text-right">
				<v-btn @click="save" color="primary" class="mr-2">{{mwtr.SaveButton}}</v-btn>
				<NextSection @save="save"></NextSection>
			</p>
			
			<Saved :trigger="saved" @saved="s => saved = s"></Saved>
		</template>
		<v-alert v-else color="secondary">
			{{mwtr.AccessDenied }}
		</v-alert>
	</v-sheet>
</template>

<style lang="less">
	.goal-toolbar {
		margin-left: -15px;
		.v-toolbar__content {
			padding-right: 0px;
		}
	}
	.goal-title {
		font-weight: 400;
		font-size: 1.4em;
	}
</style>

<script type="text/javascript">
	import Vue from "vue";
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import NextSection from "@/components/survey/tools/NextSection"
	import Questions from "@/components/survey/render/Questions"
	import CreatePoll from "@/components/survey/poll/Create"

	export default {
		name: "Section", 
		props: {
		},
		data: () => {
			return {
				saved: false,
				dataId: false,
				data: {}
			}
		},
		components: {
			Questions, 
			Saved, 
			CreatePoll,
			NextSection
		},
		computed: {
			...Vuex.mapState({
				assessment: state => state.assessment,
			}), 	
			goal(){
				return this.$store.getters.currentGoal;
			},
			goalData(){
				return this.goal.response || {};
			},
			department(){
				return this.$store.getters.currentSection;
			}, 	
			part(){
				return this.$store.getters.currentPart;
			}, 	
			questions(){
				return this.$store.getters.questionArray;
			}, 
			view(){
				return this.$store.getters.currentView 
			},
			map(){
				return this.$store.getters.igMapped
			},
			sectionId(){
				return this.department.section;
			},
			section(){
				return this.assessment.sections[this.sectionId]
			},
			error(){
				// if( this.goal.status == "pending"){
				// 	return "pending"
				// }
				if( this.map[this.view.department].status == "complete"){
					return "complete"
				}
				if( this.map[this.view.department].status == "delegated"  ){
					return "delegated"
				}
				return false;
			},
			errorMessage(){
				switch( this.error ){
					// case "pending": 
					// 	return this.mwtr.GoalPending
					case "delegated":
						return this.mwtr.DelegationWarning
					case "complete":
						return this.mwtrCompleteWarning;
					default: 
						return "";
				}
			},
			accessGranted(){
				return this.$store.getters.canAccessAssessment && typeof this.section !== "undefined"
			},

		},
		methods: {
			updateValue(id, value ){
				Vue.set(this.data, id, value)
			}, 
			save(){
				var data = {
					data: this.data,
					goal: this.view.goal,
					part: this.view.part, 
					section: this.sectionId 
				}
				if( this.dataId ){
					data.id = this.dataId;
				}
				this.$store.dispatch("assessment/saveSectionData", data).then(() => {
					this.saved = true;
				}).catch(( ) => {
					this.error = true;
				})
			}, 
			setData(result){	
				Vue.set(this, "data", result.data )
				Vue.set(this, "dataId", result.id )
			}, 
			resetData(){
				this.data = {};
				this.dataId = false;
			}, 
		},
		watch: {
			goalData: {
				immediate: true, 
				handler(result){
					if( result ){
						this.setData(result)
					}
				}
			},
			view: {
				immediate: true, 
				handler(){
					if( !this.goalData ){
						this.saved = false;			
						this.resetData();
					}
				}
			}, 

		},
		destroyed(){
		},

	}
// </script>"