<template>
	<span>	
		<v-btn @click="prev"  color="primary" v-if="!first">{{mwtr.BackButton}}</v-btn>
		<v-btn @click="next"  color="primary" v-if="!last" :class="{'ml-2': !first}">{{mwtr.NextButton}}</v-btn>
		<Return v-if="lastInSection" :disabled="!showReturnButton" :id="section" @save="$emit('save')"></Return>
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	import Return from "@/components/survey/tools/Return"
	export default {
		name: "NextSection", 
		props: {
			
		},
		data: () => {
			return {
			}
		},
		components: {
			Return
		},
		computed: {
			delegate(){
				return this.$store.getters.isCurrentlyDelegate;
			},
			department(){
				return this.$store.getters.currentSection.department;
			},
			section(){
				return this.$store.getters.currentSection.section;
			},
			departmentCompletionRate(){
				return this.$store.getters.departmentCompletionRates[this.department];
			},
			showReturnButton(){
				return this.delegate && this.lastInSection && this.departmentCompletionRate == 1;
			},
			currentGoalUniqueId(){
				return this.$store.getters.currentGoalUniqueId;
			},
			flat(){
				return this.$store.getters.igMappedFlat
			},
			current(){
				const self = this;
				var found = self.flat.find( f => {
					return 	f.uniqueId == self.currentGoalUniqueId 
				});
				if( !found ){
					return 0;
				}
				return this.flat.indexOf( found )
			},
			nextGoal(){
				return this.$store.getters.getNext;
			},
			last(){
				return this.nextGoal == false;
			},
			lastInSection(){
				var curr = this.flat[this.current];
				var next = this.flat[this.current+1];
				return this.last || curr.department !== next.department;
			},
			first(){
				return this.current == 0; 
			}
		},
		methods: {
			prev(){
				this.$emit("save");
				var prev = this.flat[this.current - 1];
				if( prev ){				
					this.$store.commit("GO_TO", prev )
				}
			},
			next(){
				this.$emit("save");
				var next = this.nextGoal;
				if( next ){				
					this.$store.commit("GO_TO", next )
				}
			}
		}

	}
// </script>"
