<template>
	<v-card flat class="pa-2 navigation-wrapper">	
		<v-list v-if="haveSections"> 
			<SectionOverview  v-for="key in order" :key="key" v-bind="map[key]"  @click="goTo(map[key].department)"></SectionOverview>
		</v-list>
		<NoSections v-else></NoSections>
	</v-card>
</template>

<style lang="less">
	.active {
	}
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import SectionOverview from "@/components/navigation/SectionOverview"
	import NoSections from "@/components/user/content/NoSections";
	export default {
		name: "AssessmentOverview", 
		props: {
			
		},
		data: () => {
			return {
			}
		},
		components: {
			NoSections,
			SectionOverview
		},
		computed: {
			...Vuex.mapState({
				organisation: state => state.organisation, 
				departments: state => state.departments,
				assessment: state => state.assessment, 
			}),
			order(){
				return this.$store.getters.orderOfDepartments
			},
			map(){
				return this.$store.getters.igMapped
			}, 
			haveSections(){
				return Object.keys(this.map).length > 0;
			}
		},
		methods: {
			goTo(departmentId){
				var part = this.map[departmentId].partOrder[0]
				var goal = this.map[departmentId].parts[part].goals[0]
				this.$store.commit("GO_TO", {
					goal: goal,
					part: part,
					department: departmentId
				})
			}
		},
		created(){	    
		}

	}
// </script>"