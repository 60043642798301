<template>
	<v-list-item @click="$emit('click')">
		<template>
			
			<v-list-item-content>
				<v-list-item-title>
					{{name}}
				</v-list-item-title>
				<v-list-item-subtitle>
					<CompletionRateBadge :part="false" :department="department" :goal="false"></CompletionRateBadge>
					<StatusChip :department="department" class="ml-2"></StatusChip>
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-icon>
				<SectionCompleteButtons :department="department"></SectionCompleteButtons>

				<v-dialog v-if="lead" v-model="dialog" :width="mwstylesettings.MediumDialogWidth">
					<template v-slot:activator="{on}">							
						<v-btn icon color="igblack" v-on="on">
							<v-icon>mdi-account-plus-outline</v-icon>
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							{{mwtr.Delegate}}
						</v-card-title>
						<v-card-text>
							<Delegate :id="section"></Delegate>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" @click="dialog = false">OK</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-list-item-icon>
		</template>
	</v-list-item>

</template>

<style lang="less">
	.active {
	}
</style>

<script type="text/javascript">
	import SectionCompleteButtons from "@/components/navigation/SectionCompleteButtons"
	import Delegate from "@/components/survey/tools/Delegate"
	import StatusChip from "@/components/navigation/StatusChip"
	import CompletionRateBadge from "@/components/navigation/CompletionRateBadge"
	export default {
		name: "AssessmentOverview", 
		props: {
			department: {}, 
			name: {}, 
			section: {},
			parts: {},
			status: {}
		},
		data: () => {
			return {
				dialog: false
			}
		},
		components: {
			Delegate, 
			StatusChip,
			CompletionRateBadge,
			SectionCompleteButtons
		},
		computed: {		
			lead(){
				return this.$store.getters.isCurrentlyLeadRespondent
			},			
		},
		methods: {
			count(){
				return Object.keys(this.parts).length;
			},
		},

	}
// </script>"