<template>
	<v-snackbar v-model="display" color="primary">{{message}}</v-snackbar>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "Notice", 
		props: {
			trigger: {}, 
			message: {}
		},
		data: () => {
			return {
				display: false
			}
		},
		watch: {
			display() {
				if( !this.display ){
					this.$emit("close")
				}
			},
			trigger: {
				immediate: true, 
				handler(){
					this.display = this.trigger
				}
			}
		}
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"